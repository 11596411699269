import { getLogger } from '@confluence/logger';
import { getIsNav4Enabled } from '@confluence/nav4-enabled';

const logger = getLogger('scroll-to-element');

export const PAGE_HEADER_HEIGHT = 60;

/**
 * Returns the HTMLElement that should be used as the scroll root. By default, this is the window.
 * For Nav 4, this is the div with id=AkMainContent, if the id exists in the DOM
 *
 * @param isNav4Enabled - boolean
 */
function getScrollRootElement(isNav4Enabled: boolean = false) {
	return isNav4Enabled ? document.getElementById('AkMainContent') ?? window : window;
}
const explicitScrollListeners = new Set<() => void>();
export function addExplicitScrollListener(listener: () => void): () => void {
	explicitScrollListeners.add(listener);

	return () => explicitScrollListeners.delete(listener);
}

function invokeExplicitScrollListeners() {
	explicitScrollListeners.forEach((listener) => {
		try {
			listener();
		} catch (error) {
			Promise.all([
				import(/* webpackChunkName: "loadable-confluencemonitoring" */ '@confluence/monitoring'),
				import(
					/* webpackChunkName: "loadable-confluenceerror-boundary" */ '@confluence/error-boundary'
				),
			])
				.then(([{ getMonitoringClient }, { Attribution }]) => {
					getMonitoringClient().submitError(error, {
						attribution: Attribution.PAGE_EXTENSIONS,
					});
				})
				.catch(() => {
					// ignore errors, we can't do anything at this point
				});
		}
	});
}

function isSmoothScrollSupported() {
	return 'scrollBehavior' in document.documentElement.style;
}

export function scrollToPageStart(isSmooth: boolean = false) {
	invokeExplicitScrollListeners();

	const isNav4Enabled = getIsNav4Enabled();
	const scrollRootElement = getScrollRootElement(isNav4Enabled);

	if (isSmooth && isSmoothScrollSupported()) {
		scrollRootElement.scrollTo({
			top: 0,
			left: 0,
			behavior: 'smooth',
		});
	} else {
		scrollRootElement.scrollTo(0, 0);
	}
}

/**
 * Scrolls element into browser view, taking various offsets into account.
 *
 * @param element - the event which you want to scroll into view
 * @param scrollCorrectionPixels - the amount of pixels the scroll position needs to be adjusted for. For example,
 * if you have a fixed-positioned element that overlaps your scroll area,
 * and that element has a height of `60` pixels, you'll set `scrollCorrectionPixels` to `-60`.
 */
export function scrollToElement(
	element: HTMLElement,
	scrollCorrectionPixels?: number,
	scrollBehavior: ScrollBehavior = 'auto',
): void {
	const isNav4Enabled = getIsNav4Enabled();
	const scrollRootElement = getScrollRootElement(isNav4Enabled);

	let parentsOffset: number = 0;
	let currentOffsetParent = element.offsetParent;
	while (currentOffsetParent instanceof HTMLElement && currentOffsetParent != scrollRootElement) {
		parentsOffset += currentOffsetParent.offsetTop;
		currentOffsetParent = currentOffsetParent.offsetParent;
	}

	const pageHeaderHeightAdjustment = isNav4Enabled ? 0 : PAGE_HEADER_HEIGHT;

	let top =
		element.offsetTop + (scrollCorrectionPixels || 0) + parentsOffset - pageHeaderHeightAdjustment;

	// check if element is nested inside table, if so we need to account for
	// sticky table header overlapping the element
	const hasTableParent = element?.closest('.pm-table-container td');
	if (hasTableParent && scrollCorrectionPixels) top = top + scrollCorrectionPixels * 1.25;

	invokeExplicitScrollListeners();

	let behavior = scrollBehavior;
	if (behavior === 'smooth' && !isSmoothScrollSupported()) {
		behavior = 'auto';
	}

	try {
		scrollRootElement.scroll({
			top,
			behavior,
		});
	} catch (err) {
		isNav4Enabled
			? logger.error`scroll() is not supported on #AkMainContent`
			: logger.error`window.scroll() is not supported`;
	}
}
