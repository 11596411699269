import React, { createContext, useMemo, useState } from 'react';
import type { FC, ReactNode } from 'react';

type pageCommentsCountContext = {
	count: number;
	setCount: (num: number) => void;
};

export const PageCommentsCountContext = createContext<pageCommentsCountContext>({
	count: 0,
	setCount: () => {},
});

type PageCommentsCountProviderProps = {
	children: ReactNode;
	contentId: string;
};

export const PageCommentsCountProvider: FC<PageCommentsCountProviderProps> = ({
	children,
	contentId,
}) => {
	const [count, setCount] = useState(0);

	const pageCommentsCountContextValue = useMemo(
		() => ({
			count,
			setCount,
		}),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[count, contentId],
	);

	return (
		<PageCommentsCountContext.Provider value={pageCommentsCountContextValue}>
			{children}
		</PageCommentsCountContext.Provider>
	);
};
