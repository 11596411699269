import React, { useMemo } from 'react';

import {
	Mutation as ApolloMutation,
	type MutationComponentOptions,
	type OperationVariables,
} from 'react-apollo';

import getOperationName from '../utils/get-operation-name';

import { withUFOHold } from './withUFOHold';

export function Mutation<
	TData extends Record<string, any> = Record<string, any>,
	TVariables extends OperationVariables = OperationVariables,
>({ children, ...props }: MutationComponentOptions<TData, TVariables>) {
	const operationName = useMemo(() => getOperationName(props.mutation), [props.mutation]);

	return (
		<ApolloMutation<TData, TVariables> {...props}>
			{withUFOHold('mutation', operationName, children)}
		</ApolloMutation>
	);
}
