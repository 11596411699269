import React from 'react';
import { useIntl } from 'react-intl-next';

import { AISummarizeButtonPlaceholder } from '@atlassian/ai-summary';

import { fg } from '@confluence/feature-gating';

import { i18n } from './i18n';
import { QuickSummaryButtonWhitePlaceholder } from './QuickSummaryButtonWhitePlaceholder';
import type { QuickSummaryButtonStyles } from './QuickSummaryButton';

type QuickSummaryButtonPlaceholderProps = {
	summarizeText?: string;
	buttonStyles?: QuickSummaryButtonStyles;
};

export const QuickSummaryButtonPlaceholder = ({
	summarizeText,
	buttonStyles,
}: QuickSummaryButtonPlaceholderProps) => {
	const { formatMessage } = useIntl();
	const buttonText = summarizeText ?? formatMessage(i18n.quickSummaryToolTipText);

	const isWhiteSummarizeButtonEnabled = fg('cc_smarts_white_summarize_button');

	if (isWhiteSummarizeButtonEnabled)
		return (
			<QuickSummaryButtonWhitePlaceholder summarizeText={buttonText} buttonStyles={buttonStyles} />
		);

	return <AISummarizeButtonPlaceholder summarizeText={buttonText} />;
};
