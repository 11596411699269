import { useContext, useEffect } from 'react';

import type { QuickReloaderType } from './QuickReloadContext';
import { QuickReloadContext } from './QuickReloadContext';

export const useQuickReloadSubscriber = (reloader: QuickReloaderType) => {
	const { contentId, registerReloader, unregisterReloader } = useContext(QuickReloadContext);

	useEffect(() => {
		registerReloader(reloader);

		return () => {
			unregisterReloader(reloader);
		};

		// NOTE: these are only dependencies of this effect
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [contentId, reloader.reload, reloader.name]);
};
