import React from 'react';

import Button from '@atlaskit/button/new';
import { AtlassianIntelligenceLogoIcon } from '@atlaskit/legacy-custom-icons';

type AISummarizeButtonPlaceholderProps = {
	summarizeText: string;
};

export const AISummarizeButtonPlaceholder = ({
	summarizeText,
}: AISummarizeButtonPlaceholderProps) => (
	<Button
		testId="ai-summarize-button-placeholder"
		isDisabled
		iconBefore={() => <AtlassianIntelligenceLogoIcon label="" />}
	>
		{summarizeText}
	</Button>
);
