import React from 'react';

import { CustomThemeButton, type ThemeProps, type ThemeTokens } from '@atlaskit/button';
import { token } from '@atlaskit/tokens';

import { QuickSummaryIcon } from './assets/QuickSummaryIcon';
import type { QuickSummaryButtonStyles } from './QuickSummaryButton';

type QuickSummaryButtonWhitePlaceholderProps = {
	summarizeText: string;
	buttonStyles?: QuickSummaryButtonStyles;
};

export const QuickSummaryButtonWhitePlaceholder = ({
	summarizeText,
	buttonStyles = {},
}: QuickSummaryButtonWhitePlaceholderProps) => {
	const customTheme = (
		currentTheme: (props: ThemeProps) => ThemeTokens,
		theme: ThemeProps,
	): ThemeTokens => {
		const existingTheme = currentTheme(theme);
		const defaultStyles = {
			buttonStyles: {
				...existingTheme.buttonStyles,
				alignItems: 'center',
				gap: token('space.025', '2px'),
				padding:
					buttonStyles?.padding || `${token('space.050', '4px')} ${token('space.100', '8px')}`,
				border: buttonStyles?.border || existingTheme.buttonStyles.border,
				borderRadius: buttonStyles?.borderRadius || existingTheme.buttonStyles.borderRadius,
			},
		};

		return {
			...existingTheme,
			...defaultStyles,
		};
	};

	return (
		<CustomThemeButton
			theme={customTheme}
			spacing="none"
			appearance="subtle"
			isDisabled
			iconBefore={<QuickSummaryIcon label="" />}
			testId="ai-summarize-button-placeholder"
		>
			{summarizeText}
		</CustomThemeButton>
	);
};
