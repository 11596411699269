import { defineMessages } from 'react-intl-next';

export const i18n = defineMessages({
	quickSummaryLabel: {
		id: 'quick-summary.label.text',
		defaultMessage: 'Quick summary',
		description: 'The label text for Quick Summary',
	},
	quickSummaryToolTipText: {
		id: 'quick-summary.tooltip.text',
		defaultMessage: 'Summarize',
		description: 'Tooltip text for Quick Summary icon',
	},
	quickSummaryFeatureSpotlightText: {
		id: 'quick-summary.feature-spotlight-one.text',
		defaultMessage:
			'This might be a longer read. Save time and read a quick summary from Atlassian Intelligence.',
		description: 'Description text for spotlight',
	},
	quickSummarySpotlightSummarizeText: {
		id: 'quick-summary.spotlight.summarize.button.text',
		defaultMessage: 'Summarize',
		description: 'Summarize button text for spotlight',
	},
	quickSummarySpotlightCloseButtonText: {
		id: 'quick-summary.spotlight.close.button.text',
		defaultMessage: 'Dismiss',
		description: 'Close button text for spotlight button',
	},
	quickSummaryOptInDialogHeaderText: {
		id: 'quick-summary.opt-in.dialog.header.text',
		defaultMessage: 'Work smarter, not harder',
		description: 'Header text for Quick Summary AI Opt In Dialog',
	},
	quickSummaryOptInDialogBodyText: {
		id: 'quick-summary.opt-in.dialog.body.text',
		defaultMessage:
			'Save time by reading an Al-generated summary instead of an entire page or blog with <a>Atlassian Intelligence</a>. <br></br><br></br> Interested in trying Atlassian Intelligence? Your admin can activate AI features from Atlassian Administration.',
		description: 'Body text for Quick Summary AI Opt In Dialog',
	},
	quickSummaryAdminOptInDialogBodyText: {
		id: 'quick-summary.admin.opt-in.dialog.body.text',
		defaultMessage:
			'Save your team time by reading an Al-generated summary instead of an entire page with <a>Atlassian Intelligence</a>. <br></br><br></br> Activate Atlassian Intelligence features for your users from Atlassian Administration.',
		description: 'Body text for Quick Summary AI Opt In Dialog',
	},
});
